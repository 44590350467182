import { useState } from 'react'
import { useTranslation } from 'next-i18next'

import { Box, Button, Dialog, Typography } from '@mui/material'

const styles = {
  container: {
    maxWidth: '400px',
    padding: 2,
  },
  text: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 2,
    marginTop: 2,
  },
}

export const ExternalFilesMobile: React.FC<{
  url: string
  tooltipContent: string
  buttonText: string
  onClick?: () => void
}> = ({ url, tooltipContent, buttonText, onClick }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.actionsBar',
  })

  const handleOpen = () => {
    onClick?.()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button
        onClick={handleOpen}
        href=""
        target="_blank"
        variant="outlined"
        size="medium"
        fullWidth
        data-cy="external-files-button"
      >
        {buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <Box sx={styles.container}>
          {/* TODO We shouldn't give lineHeight or fontSize manually. Clarify designs */}
          <Typography fontSize={13} lineHeight={1.7} variant="body2" color="text.primary">
            {tooltipContent}
          </Typography>
          <Box sx={styles.text}>
            <Button onClick={handleClose} variant="text" size="medium">
              {t('buttons.dismiss')}
            </Button>
            <Button
              onClick={handleClose}
              href={url}
              target="_blank"
              size="medium"
              variant="contained"
            >
              {t('buttons.open')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}
