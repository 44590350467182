import { Box, Button, Tooltip, Typography } from '@mui/material'

const TooltipContent: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Typography fontSize={13} lineHeight={1.7}>
      {title}
    </Typography>
  )
}

export const ExternalFilesDesktop: React.FC<{
  url: string
  tooltipContent: string
  buttonText: string
  onClick?: () => void
}> = ({ url, tooltipContent, buttonText, onClick }) => {
  return (
    <Tooltip title={<TooltipContent title={tooltipContent} />} arrow>
      <Box width="100%">
        <Button
          href={url}
          onClick={onClick}
          target="_blank"
          variant="outlined"
          size="medium"
          fullWidth
          data-cy="external-files-button"
        >
          {buttonText}
        </Button>
      </Box>
    </Tooltip>
  )
}
