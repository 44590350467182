import type { PropsWithChildren } from 'react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import env from '@beam-australia/react-env'
import type { FooterProps } from '@knauf-group/ct-designs/components/core/Footer'
import type { HeaderMainMenu } from '@knauf-group/ct-designs/components/core/WebHeader'
import type { IAppsConfig } from '@knauf-group/ct-shared-nextjs/client'
import { WebFooter } from '@knauf-group/ct-shared-nextjs/components/WebFooter/WebFooter'
import logger from '@knauf-group/ct-shared-nextjs/logger'
import { Container, Toolbar } from '@mui/material'
import { ANALYTICS_CONSTS } from 'src/hooks/analytics/const'

import type ScrollTop from '@/components/ScrollTop'

import { Header } from './Header'
import { Navigation } from './Navigation'

const DynamicScrollTop = dynamic<React.ComponentProps<typeof ScrollTop>>(
  () => import('@/components/ScrollTop'),
  {
    ssr: false,
  },
)

type LayoutProps = {
  headerEntries: HeaderMainMenu[]
  footerEntries: FooterProps
  appsConfig: IAppsConfig
}

export const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({
  headerEntries,
  footerEntries,
  appsConfig,
  children,
}) => {
  const { t } = useTranslation('cms', { keyPrefix: 'common' })
  const { t: tDC } = useTranslation('download-center')

  const { locale } = useRouter()

  const toggleCookiesBanner = () => {
    try {
      // @ts-expect-error we don't know OneTrust type, but we know it has ToggleInfoDisplay
      window.OneTrust.ToggleInfoDisplay()
    } catch (error: any) {
      // most of the time the error happens because the browser blocks all the cookies
      logger.error(error?.message)
    }
  }

  return (
    <>
      <Head>
        <title>Download Center | Knauf</title>
        <meta name="description" content={tDC('mainPage.meta.description')} />
        <link rel="shortcut icon" href="https://knauf.com/favicon.ico" />
        <link
          rel="canonical"
          href={`${env('HOST_BASE_URL')}/${locale}/tools/download-center`}
        />
      </Head>

      <Header appsConfig={appsConfig} headerEntries={headerEntries} />
      <Toolbar />
      <Container maxWidth={false} disableGutters>
        <Container component="main" maxWidth="xl">
          <Navigation />
          {children}
        </Container>
      </Container>

      <DynamicScrollTop />

      <WebFooter
        {...footerEntries}
        languageText={t('languageSelector')}
        cookieSetting={{
          onClick: toggleCookiesBanner,
          cookieSettingLabel: t('cookies.cookiesSettings'),
        }}
        nextLinkComponent={Link}
        analytics={{ eventPayload: { page_name: ANALYTICS_CONSTS.page_name } }}
      />
    </>
  )
}
