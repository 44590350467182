import type { Theme } from '@mui/material'
import { useMediaQuery } from '@mui/material'

import { useAnalyticsTracking } from '@/hooks'

import { ExternalFilesDesktop } from './ExternalFilesDesktop'
import { ExternalFilesMobile } from './ExternalFilesMobile'

type ExternalFilesButtonProps = {
  url: string
  tooltipContent: string
  buttonText: string
}

export const ExternalFilesButton: React.FC<ExternalFilesButtonProps> = ({
  url,
  tooltipContent,
  buttonText,
}) => {
  const { trackClickEvent } = useAnalyticsTracking()
  const isScreenLgDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'))

  const handleTracking = () => trackClickEvent(buttonText, url, 'external_cta_click')

  return isScreenLgDown ? (
    <ExternalFilesMobile
      url={url}
      tooltipContent={tooltipContent}
      buttonText={buttonText}
      onClick={handleTracking}
    />
  ) : (
    <ExternalFilesDesktop
      url={url}
      tooltipContent={tooltipContent}
      buttonText={buttonText}
      onClick={handleTracking}
    />
  )
}
