import { useRouter } from 'next/router'

import type { Theme } from '@mui/material'
import { Stack, useMediaQuery } from '@mui/material'

import { DownloadButton } from './DownloadButton'
import { FilesButtonBauprodukte } from './FilesButtonBauprodukte'
import { FilesButtonGypsum } from './FilesButtonGypsum'
import { FilesButtonInsulation } from './FilesButtonInsulation'
import { FiltersButton } from './FiltersButton'
import { Header } from './Header'
import { SearchBox } from './SearchBox'

const HorizontalLayout = ({ showGypsumBtn, showFiltersBtn }) => {
  return (
    <Stack gap={3} direction="row">
      <Stack direction="row" justifyContent="space-between" flexWrap="wrap" gap={3} flex={1}>
        {showGypsumBtn ? (
          <>
            <FilesButtonGypsum />
            <FilesButtonBauprodukte />
          </>
        ) : (
          <FilesButtonInsulation />
        )}
        <SearchBox />
      </Stack>
      <Stack
        justifyContent="space-between"
        alignItems="flex-end"
        flex={1}
        direction={showGypsumBtn ? 'column' : 'column-reverse'}
      >
        {showGypsumBtn && <FilesButtonInsulation />}
        {showFiltersBtn ? <FiltersButton /> : <DownloadButton />}
      </Stack>
    </Stack>
  )
}

const VerticalLayout = ({ showGypsumBtn }) => {
  return (
    <Stack gap={2}>
      {showGypsumBtn && <FilesButtonGypsum />}
      <FilesButtonInsulation />
      {showGypsumBtn && <FilesButtonBauprodukte />}
      <FiltersButton />
      <SearchBox />
    </Stack>
  )
}

export const ActionsBar = () => {
  const isScreenLgDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'))
  const isScreenMdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const { locale } = useRouter()
  const showGypsumBtn = locale === 'de-DE'

  return (
    <Stack mb={3} gap={3}>
      <Header />
      {isScreenMdDown ? (
        <VerticalLayout showGypsumBtn={showGypsumBtn} />
      ) : (
        <HorizontalLayout showGypsumBtn={showGypsumBtn} showFiltersBtn={isScreenLgDown} />
      )}
    </Stack>
  )
}
