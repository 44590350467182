import { useInfiniteHits } from 'react-instantsearch-core'
import dynamic from 'next/dynamic'

import type { Theme } from '@mui/material'
import { Box, useMediaQuery } from '@mui/material'

import { ActionsBar } from './ActionsBar'
import { ActualHits } from './Hits/ActualHits'
import { ShowMoreWidget } from './ShowMoreWidget'

const styles = (isScreenMdUp?: boolean) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      p: isScreenMdUp ? 3 : 0,
      pt: isScreenMdUp ? 2 : 0,
      backgroundColor: isScreenMdUp ? 'none' : (theme) => theme.palette.background.paper,
    },
    assetsCart: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 'auto',
      position: 'sticky',
      bottom: '20px',
      visibility: 'hidden',
    },
  }
}

const DynamicAssetsCart = dynamic(
  () => import('./AssetsCart').then((module) => module.AssetsCart),
  {
    ssr: false,
  },
)

export const SearchAndHitsPanel = () => {
  const { hits } = useInfiniteHits()
  const isScreenMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  return (
    <Box sx={styles(isScreenMdUp).container}>
      <ActionsBar />
      <ActualHits />
      {hits?.length > 0 && <ShowMoreWidget />}
      <Box sx={styles().assetsCart}>
        <DynamicAssetsCart />
      </Box>
    </Box>
  )
}
