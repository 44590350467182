import type { ImageLoader } from 'next/image'

import { ASSET_PREFIX } from '@/constants'

import { removeDoubleSlashes } from './removeDoubleSlashes'

const getStaticAssetPath = (path: string) => removeDoubleSlashes(`/${ASSET_PREFIX}/${path}`)

export const imageLoader: ImageLoader = ({ src, width, quality }) => {
  // TODO: decide what to do if the image has w and q params already (like images from unsplash api)
  // because having 2 w or q params will break the image

  return getStaticAssetPath(`/dlc-fe/?url=${src}&w=${width}&q=${quality ?? 75}`)
}
