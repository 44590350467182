import { useState } from 'react'
import { useTranslation } from 'next-i18next'

import { Store } from '@mui/icons-material'
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'

import { STORE_AVAILABILITY_TITLE } from '@/constants'
import type { AssetModel } from '@/types'

const styles = {
  container: {
    maxWidth: '400px',
    padding: 2,
  },
  text: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 1,
    marginTop: 2,
  },
  iconButton: {
    position: 'absolute',
    padding: 0,
    width: '16px',
    height: '13px',
  },
  main: {
    padding: '5px',
    pl: 0,
  },
}

interface HardwareStoreCellRendererMobileProps {
  params: AssetModel
}

export const HardwareStoreCellRendererMobile: React.FC<
  HardwareStoreCellRendererMobileProps
> = ({ params: row }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.actionsBar',
  })
  const isHardware = Object.values(row?.storeAvailability ?? {}).includes(
    STORE_AVAILABILITY_TITLE,
  )

  const handleOpen = () => setOpen(true)

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setOpen(false)
  }

  if (isHardware) {
    return (
      <>
        <Box sx={styles.main}>
          <IconButton onClick={handleOpen} sx={styles.iconButton}>
            <Store sx={{ color: 'text.secondary' }} fontSize="small" />
          </IconButton>
        </Box>
        <Dialog open={open} onClose={handleClose}>
          <Box sx={styles.container}>
            {/* TODO do we need to specify fontSize or lineHeight? */}
            <Typography fontSize={13} lineHeight={1.7} variant="body2">
              {STORE_AVAILABILITY_TITLE}
            </Typography>
            <Box sx={styles.text}>
              <Button onClick={handleClose} variant="contained" size="small">
                {t('buttons.dismiss')}
              </Button>
            </Box>
          </Box>
        </Dialog>
      </>
    )
  }
  return null
}

export default HardwareStoreCellRendererMobile
