import { useTranslation } from 'next-i18next'

import { Breadcrumbs } from '@knauf-group/ct-designs/components/core/Breadcrumbs'
import { useBreadcrumbsLinks } from 'src/hooks/useBreadcrumbsLinks'

export const Navigation = () => {
  const links = useBreadcrumbsLinks()
  const { t } = useTranslation('download-center', { keyPrefix: 'common.navigation' })

  return <Breadcrumbs links={links} backLabel={t('buttons.back')} />
}
