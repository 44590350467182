import { Store } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'

import { STORE_AVAILABILITY_TITLE } from '@/constants'
import type { AssetModel } from '@/types'

const styles = {
  container: {
    padding: '5px',
    pl: 0,
  },
  iconButton: {
    position: 'absolute',
    padding: 0,
    width: '16px',
    height: '13px',
  },
  iconColor: {
    color: 'text.secondary',
  },
}

const TooltipContent: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Typography fontSize={13} lineHeight={1.7}>
      {title}
    </Typography>
  )
}

interface HardwareStoreCellRendererDesktopProps {
  params: AssetModel
}

export const HardwareStoreCellRendererDesktop: React.FC<
  HardwareStoreCellRendererDesktopProps
> = ({ params: row }) => {
  const isHardware = Object.values(row?.storeAvailability ?? {}).includes(
    STORE_AVAILABILITY_TITLE,
  )

  if (isHardware) {
    return (
      <Tooltip title={<TooltipContent title={STORE_AVAILABILITY_TITLE} />} arrow>
        <Box sx={styles.container}>
          <IconButton sx={styles.iconButton}>
            <Store sx={styles.iconColor} fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    )
  }
  return null
}

export default HardwareStoreCellRendererDesktop
