import { useTranslation } from 'next-i18next'

import { ExternalFilesButton } from '@/components'

const EXTERNAL_URL = 'https://www.knaufinsulation.com/iframe-cprdop'

export const FilesButtonInsulation = () => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.actionsBar',
  })

  return (
    <ExternalFilesButton
      url={EXTERNAL_URL}
      tooltipContent={t('tooltip')}
      buttonText={t('buttons.externalFiles')}
    />
  )
}
