import { useTranslation } from 'next-i18next'

import { Typography } from '@mui/material'

type HeaderProps = {
  hide?: boolean
}

export const Header: React.FC<HeaderProps> = ({ hide = false }) => {
  const { t } = useTranslation('download-center', { keyPrefix: 'mainPage.heroTeaser' })

  return (
    <Typography
      component="h1"
      variant="h4"
      color="text.primary"
      hidden={hide}
      data-cy="DownloadDocuments-Title"
    >
      {t('headline')}
    </Typography>
  )
}
