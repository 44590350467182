import dynamic from 'next/dynamic'

import type { Theme } from '@mui/material'
import { Skeleton, useMediaQuery } from '@mui/material'

const DynamicMobileFilters = dynamic(
  () => import('./MobileFilters').then((module) => module.MobileFilters),
  {
    loading: () => <Skeleton />,
  },
)

const DynamicTabletFilters = dynamic(
  () => import('./TabletFilters').then((module) => module.TabletFilters),
  {
    loading: () => <Skeleton />,
  },
)

const DynamicDesktopFilters = dynamic(
  () => import('./DesktopFilters').then((module) => module.DesktopFilters),
  {
    loading: () => <Skeleton />,
  },
)

export const FiltersPanel = () => {
  // TODO In SSR, all of those below are false, leading no sidebar render. When testing,
  // change `isDesktop` to `!(isMobile || isTablet)` to see desktop sidebar rendered.
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.between('sm', 'lg'))

  return (
    <>
      {isDesktop && <DynamicDesktopFilters />}
      {isMobile && <DynamicMobileFilters />}
      {isTablet && <DynamicTabletFilters />}
    </>
  )
}
