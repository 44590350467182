import { useState } from 'react'
import { useSearchBox } from 'react-instantsearch-core'
import { useTranslation } from 'next-i18next'

import Searchbar, {
  validateSearchQuery,
} from '@knauf-group/ct-designs/components/core/Searchbar/Searchbar'
import { Box } from '@mui/material'
import { debounce } from 'lodash'

const debounceQuery = debounce(function (query, refine) {
  refine(query)
}, 500)

export const SearchBox = () => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.actionsBar',
  })

  const { query, refine } = useSearchBox({ queryHook: debounceQuery })
  const [internalQuery, setInternalQuery] = useState(query)

  const handleOnClear = () => {
    setInternalQuery('')
    refine('')
  }

  const handleQueryChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target
    const previousInternalQuery = internalQuery

    setInternalQuery(value)

    // if the user cleared the query manually we need to handle it as well
    if (value.length === 0) {
      handleOnClear()
      return
    }

    const valueTrimmed = value.trim()

    if (validateSearchQuery(value) && previousInternalQuery !== valueTrimmed) {
      refine(valueTrimmed)
    }
  }

  return (
    <Box sx={{ minHeight: '20px', width: '100%' }}>
      <Searchbar
        placeholder={t('search')}
        value={internalQuery}
        onChange={handleQueryChange}
        onClear={handleOnClear}
        fullWidth
        data-cy="Search-TextField"
      />
    </Box>
  )
}
