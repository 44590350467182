import { useEffect } from 'react'

import { defaultValues, useGetCustomPropertiesFromAlgolia } from './propertiesHelpers'
import { useInitializeAnalytics } from './useInitializeAnalytics'

export const usePageView = () => {
  const { analytics, isReady } = useInitializeAnalytics()
  const customProperties = useGetCustomPropertiesFromAlgolia()

  useEffect(() => {
    if (isReady) {
      analytics.page({ ...defaultValues, ...customProperties })
    }
  }, [
    isReady,
    customProperties.page_product_category,
    customProperties.page_product_type,
    customProperties.page_product_family,
  ])
}
