import { useTranslation } from 'next-i18next'

import { FilterList } from '@mui/icons-material'
import { Button } from '@mui/material'

import { setFiltersDrawerOpen, useGlobalParamsContext } from '@/contexts/GlobalParamsContext'

export const FiltersButton = () => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.actionsBar',
  })
  const { dispatch } = useGlobalParamsContext()
  const openDrawer = () => dispatch(setFiltersDrawerOpen(true))

  return (
    <Button
      size="medium"
      onClick={openDrawer}
      data-cy="filters-button"
      variant="outlined"
      startIcon={<FilterList />}
    >
      {t('buttons.filters')}
    </Button>
  )
}
