import { useEffect, useState } from 'react'
import { useInfiniteHits, useInstantSearch } from 'react-instantsearch-core'
import { useTranslation } from 'next-i18next'

import type { Theme } from '@mui/material'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import type { Hit } from 'instantsearch.js'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { useSnackbar } from 'notistack'

import {
  getSelectedAssetIds,
  toggleSelectedAsset,
  useCartContext,
} from '@/contexts/CartContext'
import type { AssetModel } from '@/types'

import { DownloadCellRenderer } from './cellRenderers/DownloadCellRenderer'
import { NameCellRenderer } from './cellRenderers/NameCellRenderer'
import { RowSelectionCellRenderer } from './cellRenderers/RowSelectionCellRenderer'
import { NoRowsOverlay } from './NoRowsOverlay'

export const ActualHits = () => {
  const { state, dispatch } = useCartContext()
  const { hits } = useInfiniteHits<AssetModel>()

  const { status } = useInstantSearch()

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.hitsDataGrid',
  })

  const isScreenLgDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'))

  const rowHeight = isScreenLgDown ? 120 : 100

  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([])
  useEffect(() => {
    setSelectedAssetIds(getSelectedAssetIds(state))
  }, [state])

  const selectionState = selectedAssetIds.reduce((acc, id) => {
    acc[id] = true

    return acc
  }, {})

  const table = useMaterialReactTable<Hit<AssetModel>>({
    // features
    enableTopToolbar: false,
    enableTableHead: false,
    enableTableFooter: false,
    enablePagination: false,
    enableRowSelection: !isScreenLgDown, // only on desktop
    enableRowActions: false,
    enableBottomToolbar: false,
    enableDensityToggle: false,

    // styling
    layoutMode: 'grid',
    muiTablePaperProps: { elevation: 0 },
    muiTableContainerProps: { sx: { boxShadow: 'none' } },
    muiTableBodyCellProps: {
      height: rowHeight,
      // RTL integration automatically makes it `right` when needed
      align: 'left',
    },
    renderEmptyRowsFallback: () => <NoRowsOverlay />,

    // functionality
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const selectedAsset = row.original

        if (
          selectedAssetIds.length >= 10 &&
          !selectedAssetIds.includes(selectedAsset.assetId)
        ) {
          return enqueueSnackbar(t('snackbarMessage'), {
            variant: 'info',
            preventDuplicate: true,
          })
        }

        dispatch(
          toggleSelectedAsset({ ...selectedAsset, selectedAt: new Date().toISOString() }),
        )
      },
      sx: (theme) => {
        return {
          cursor: !isScreenLgDown ? 'pointer' : 'default',

          // selected row
          '&.MuiTableRow-root.Mui-selected td:after': {
            backgroundColor: theme.palette.action.selected,
          },
          // selected+hovered row
          '&.MuiTableRow-root.Mui-selected:hover td': {
            backgroundColor: theme.palette.action.hover,
          },
          // hovered row
          '&.MuiTableRow-root:hover td:after': {
            backgroundColor: theme.palette.action.hover,
          },
        }
      },
      'data-cy': `DownloadDocument-GridItem-${row.original.assetId}`,
    }),
    state: {
      isLoading: status === 'stalled',
      // no row selection on mobile/tablet
      rowSelection: isScreenLgDown ? [] : selectionState,
      columnVisibility: {
        // we have our own custom checkbox column
        'mrt-row-select': false,
        'selection-checkbox': !isScreenLgDown,
      },
    },

    // data
    getRowId: (row) => row.assetId,
    data: hits,
    columns: [
      {
        header: 'selection-checkbox',
        Cell: RowSelectionCellRenderer(selectedAssetIds),
        size: 60,
        grow: false,
        muiTableBodyCellProps: { align: 'center' },
      },
      {
        header: 'name',
        accessorKey: 'name',
        Cell: NameCellRenderer,
        grow: true,
        muiTableBodyCellProps: { sx: { paddingLeft: '8px !important' } },
      },
      {
        header: 'download',
        size: 60,
        grow: false,
        muiTableBodyCellProps: { align: 'center' },
        Cell: DownloadCellRenderer,
      },
    ],
  })

  return (
    <Box mb={3} data-cy="DownloadDocuments-ResultsTable">
      <MaterialReactTable table={table} />
    </Box>
  )
}
