import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import type { HeaderMainMenu } from '@knauf-group/ct-designs/components/core/WebHeader'
import type { IAppsConfig } from '@knauf-group/ct-shared-nextjs/client'
import { WebHeader } from '@knauf-group/ct-shared-nextjs/components/WebHeader/WebHeader'
import { useClickEvent } from '@knauf-group/ct-shared-nextjs/web/hooks'
import { Box } from '@mui/material'
import { ANALYTICS_CONSTS } from 'src/hooks/analytics/const'

type HeaderProps = {
  headerEntries: HeaderMainMenu[]
  appsConfig: IAppsConfig
}

const Header = ({ headerEntries, appsConfig }: HeaderProps) => {
  const router = useRouter()
  const { t } = useTranslation('download-center', { keyPrefix: 'common' })
  const { push: pushClickEvent } = useClickEvent()

  const onClickLogo = () => {
    pushClickEvent?.({
      reference: { label: 'logo', href: '/', external: false },
      type: 'link',
      eventPayload: {
        page_name: ANALYTICS_CONSTS.page_name,
        target: '/',
        page_section: 'header',
      },
    })

    router.push('/')
  }

  return (
    <Box sx={{ '& .web-layout-wrapper': { maxWidth: '90rem', mx: 'auto' } }}>
      <WebHeader
        analytics={{ eventPayload: { page_name: ANALYTICS_CONSTS.page_name } }}
        appsConfig={appsConfig}
        navigationLinks={headerEntries}
        showAllLabel={t('header.showAll')}
        onClickLogo={onClickLogo}
        nextLinkComponent={Link}
      />
    </Box>
  )
}

export { Header }
