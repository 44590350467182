import { useInfiniteHits } from 'react-instantsearch-core'

import { SaveAltOutlined } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import type { Hit } from 'instantsearch.js'
import type { MRT_ColumnDef } from 'material-react-table'
import { useAnalyticsTracking } from 'src/hooks'
import { getActionForDownloadEvent } from 'src/utils/getActionForDownloadEvent'
import { reduceAssetModel } from 'src/utils/reduceAssetModel'

import {
  DOWNLOAD_CENTER_SERVICE_URL,
  SINGLE_DOCUMENT_DOWNLOAD_BUTTON_DATACY,
} from '@/constants'
import type { AssetModel } from '@/types'

export const DownloadCellRenderer: MRT_ColumnDef<Hit<AssetModel>>['Cell'] = (props) => {
  const {
    row: { original: row },
  } = props

  const { sendEvent } = useInfiniteHits()

  const reducedAsset = reduceAssetModel(row)

  const { trackDownload } = useAnalyticsTracking()

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    sendEvent('click', row, 'click download')
    trackDownload(getActionForDownloadEvent(e), e.currentTarget.getAttribute('href') ?? '', [
      reducedAsset,
    ])
  }

  return (
    <Box className="download-cell">
      <IconButton
        onClick={handleOnClick}
        href={`${DOWNLOAD_CENTER_SERVICE_URL}/assets/${row.assetId}?download=true`}
        target="_blank"
        aria-label="download"
        data-cy={`${SINGLE_DOCUMENT_DOWNLOAD_BUTTON_DATACY}-${row.assetId}`}
      >
        <SaveAltOutlined color="secondary" />
      </IconButton>
    </Box>
  )
}
