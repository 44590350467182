import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { DEFAULT_CROWDIN_LOCALE } from '@knauf-group/ct-shared-nextjs/utils/constants'
import type { Theme } from '@mui/material'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import type { Hit } from 'instantsearch.js'
import type { MRT_ColumnDef } from 'material-react-table'
import prettyBytes from 'pretty-bytes'

import type { AssetModel } from '@/types'

import { HardwareStoreCellRendererDesktop } from './HardwareCellRenderer/HardwareStoreCellRendererDesktop'
import { HardwareStoreCellRendererMobile } from './HardwareCellRenderer/HardwareStoreCellRendererMobile'

const styles = (isScreenSmDown?: boolean) => {
  return {
    container: {
      paddingRight: '16px',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    textStyle: {
      color: 'text.primary',
      maxWidth: '650px',
      whiteSpace: 'break-spaces',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      overflowWrap: 'anywhere',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: isScreenSmDown ? '3' : '2',
    },
  }
}

const formatDate = (unixTime: string, locale: string = DEFAULT_CROWDIN_LOCALE) => {
  const date = new Date(parseInt(unixTime, 10))

  return date.toLocaleDateString(locale, { month: 'short', year: 'numeric' })
}

type InfoTextProps = {
  text: any
  dataCy?: string
}

const InfoText = ({ text, dataCy }: InfoTextProps) => {
  return (
    <Typography variant="body3" color="text.secondary" data-cy={dataCy}>
      {text}
    </Typography>
  )
}

const formatDocumentType = (documentTypes: string[], t: any) => {
  return t(documentTypes[0]) || ''
}

export const NameCellRenderer: MRT_ColumnDef<Hit<AssetModel>>['Cell'] = (props) => {
  const {
    row: { original: row },
  } = props

  const hasHardwareIconWithDialog = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('lg'),
  )

  const { documentTypes, name, fileFormat, fileSize, pageCount, language, lastUpdated } = row
  const { t } = useTranslation('download-center', { keyPrefix: 'mainPage.searchAndHitsPanel' })
  const { t: tDA } = useTranslation('dam-assets', { keyPrefix: 'assetCategories' })
  const { locale } = useRouter()

  const isScreenMdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const isScreenSmDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))

  const displayFileFormat = !isScreenMdDown && fileFormat
  const displayFileSize = !isScreenMdDown && fileSize
  const displayPageCount = !isScreenMdDown && pageCount
  const displayLanguage = !isScreenMdDown && language

  return (
    <Box sx={styles().container}>
      <Typography
        variant="body2"
        sx={styles(isScreenSmDown).textStyle}
        data-cy={`DownloadDocument-TableItem-title-${row.assetId}`}
      >
        {name}
      </Typography>
      <Stack direction="row" spacing={1} sx={{ height: '23px' }}>
        <InfoText text={formatDocumentType(documentTypes, tDA)} />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ height: '23px' }}>
        {lastUpdated && (
          <InfoText
            text={formatDate(lastUpdated, locale)}
            dataCy={`DownloadDocument-TableItem-LastUpdatedDate-${row.assetId}`}
          />
        )}
        {displayFileFormat && (
          <InfoText
            text={fileFormat}
            dataCy={`DownloadDocument-TableItem-FileFormat-${row.assetId}`}
          />
        )}
        {displayFileSize && (
          <InfoText
            text={prettyBytes(parseInt(fileSize, 10), { locale })}
            dataCy={`DownloadDocument-TableItem-FileSize-${row.assetId}`}
          />
        )}
        {displayPageCount && (
          <InfoText
            // TODO why cannot i18next figure out the correct key for pl-pl?
            text={t(pageCount === 1 ? 'hitsDataGrid.page_one' : 'hitsDataGrid.page_other', {
              count: pageCount,
            })}
            dataCy={`DownloadDocument-TableItem-NumPages-${row.assetId}`}
          />
        )}
        {displayLanguage && (
          <InfoText
            text={language}
            dataCy={`DownloadDocument-TableItem-Language-${row.assetId}`}
          />
        )}
        {hasHardwareIconWithDialog ? (
          <HardwareStoreCellRendererMobile params={row} />
        ) : (
          <HardwareStoreCellRendererDesktop params={row} />
        )}
      </Stack>
    </Box>
  )
}
