import Checkbox from '@mui/material/Checkbox'

export const RowSelectionCellRenderer =
  (selectedAssetIds: string[]) =>
  ({ row }) => {
    return (
      <Checkbox
        color="secondary"
        checked={selectedAssetIds.includes(row.original.assetId)}
        data-cy={`DownloadDocument-TableItem-checkbox-${row.original.assetId}`}
      />
    )
  }
