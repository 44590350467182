import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'

import { SaveAltOutlined } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'

import { DOWNLOAD_BUTTON_DATACY } from '@/constants'
import { getSelectedAssets, useCartContext } from '@/contexts/CartContext'
import { useDownloadAssets } from '@/hooks'

export const DownloadButton = () => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.actionsBar',
  })

  const { state } = useCartContext()
  const selectedAssets = getSelectedAssets(state)

  const { href, handleOnClick } = useDownloadAssets(state, selectedAssets)

  const [assetsCount, setAssetsCount] = useState(0)

  useEffect(() => {
    setAssetsCount(selectedAssets.length)
  }, [selectedAssets.length])

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography noWrap variant="body2" color="text.secondary" data-cy="files-selected-label">
        {/* TODO why cannot i18next figure out the correct key for pl-pl? */}
        {t(assetsCount === 1 ? 'filesSelected_one' : 'filesSelected_other', {
          count: assetsCount,
        })}
      </Typography>
      <IconButton
        color="secondary"
        disabled={assetsCount === 0}
        data-cy={DOWNLOAD_BUTTON_DATACY}
        href={href}
        onClick={handleOnClick}
      >
        <SaveAltOutlined />
      </IconButton>
    </Stack>
  )
}
