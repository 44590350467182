import { useRouter } from 'next/router'

import { findKey } from 'lodash'

export const useGetQueryParamValue = (partialKey: string) => {
  const { query } = useRouter()

  const queryKey = findKey(query, (_, key) => key.includes(partialKey))
  const queryValue = query[queryKey || ''] || ''

  return queryValue
}
