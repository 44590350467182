import { useTranslation } from 'next-i18next'

import { ExternalFilesButton } from '@/components'

const EXTERNAL_URL = 'https://pd.knauf.de/start.php?frm_hersteller=1001'

export const FilesButtonGypsum = () => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.actionsBar',
  })

  return (
    <ExternalFilesButton
      url={EXTERNAL_URL}
      tooltipContent={t('tooltipgypsum')}
      buttonText={t('buttons.externalGypsumFiles')}
    />
  )
}
