import { nonNullable } from 'next/dist/lib/non-nullable'

import logger from '@knauf-group/ct-shared-nextjs/logger'
import type { StateMapping, UiState } from 'instantsearch.js'
import type { BrowserHistoryArgs } from 'instantsearch.js/es/lib/routers/history'
import type { IndexUiState } from 'instantsearch.js/es/types/ui-state'
import { pickBy } from 'lodash'

import type { QueryParams } from './algoliaConstants'
import {
  ALGOLIA_FACETS,
  ALGOLIA_FACETS_MAP,
  ALGOLIA_NAME_ASC_SORT_INDEX_SUFFIX,
  ALGOLIA_NAME_DESC_SORT_INDEX_SUFFIX,
} from './algoliaConstants'
import { isNotEmpty } from './isEmpty'

const normalizeMultiselectQueryParam = (queryParam?: string | string[]) => {
  if (queryParam == null) return []
  if (Array.isArray(queryParam)) return queryParam
  return [queryParam].filter(nonNullable)
}

export const generateIndexUiStateFromQueryParams = (args: {
  queryParams: QueryParams
  indexName: string
  language: string
}) => {
  const { queryParams, indexName, language } = args

  // 'name_asc' => 'dev_products_be_name_asc'
  // undefined => undefined
  // 'name_ascc' => undefined because is not valid value
  const sortBy = (() => {
    if (queryParams.order == null) return undefined

    const isValidSortBy = [
      ALGOLIA_NAME_ASC_SORT_INDEX_SUFFIX,
      ALGOLIA_NAME_DESC_SORT_INDEX_SUFFIX,
    ].includes(queryParams.order)

    if (!isValidSortBy) return undefined

    return `${indexName}_${queryParams.order}`
  })()

  // key is facet, value is normalized multiselect query param
  const refinementList = Object.entries(ALGOLIA_FACETS_MAP.refinementList).reduce(
    (acum, [facet, facetProps]) => {
      const languageSuffixNeeded =
        'hasLanguageSuffix' in facetProps && facetProps.hasLanguageSuffix
      if (languageSuffixNeeded) facet = `${facet}.${language}`

      const key = facet
      const value = normalizeMultiselectQueryParam(queryParams[facetProps.urlParam])

      return {
        ...acum,
        [key]: value,
      }
    },
    {} as { [attribute: string]: string[] },
  )

  // hierarchicalMenu
  const hasHierarchicalMenu =
    ALGOLIA_FACETS.productCategories.urlParam in queryParams &&
    queryParams[ALGOLIA_FACETS.productCategories.urlParam] != null

  const hierarchicalMenu = hasHierarchicalMenu
    ? {
        [`${ALGOLIA_FACETS.productCategories.facet}.lvl0.${language}`]: (queryParams[
          ALGOLIA_FACETS.productCategories.urlParam
        ] as string)!?.split(' > '),
      }
    : undefined

  const indexUiState: IndexUiState = {
    query: queryParams.query,
    sortBy,
    refinementList: pickBy(refinementList, isNotEmpty),
    hierarchicalMenu,
  }

  return pickBy(indexUiState, isNotEmpty)
}

const extractLocaleFromPathname = (pathname: Location['pathname']) => {
  try {
    return pathname.split('/')[1]
  } catch (error) {
    logger.error('extractLocaleFromLocation > wrong pathname', error)
    throw error
  }
}
const extractPageSlugFromPathname = (pathname: Location['pathname']) => {
  try {
    return pathname.split('/')[4]
  } catch (error) {
    logger.error('extractPageSlugFromPathname > wrong pathname', error)
    throw error
  }
}

export const removeDoubleSlashes = (path: string) => {
  return path.replace(/([^:]\/)\/+/g, '$1')
}

const generateUrl = (props: {
  protocol: string
  hostname: string
  pathname: string
  port?: string
  path: string
  hash: string
}) => {
  const { protocol, hostname, pathname, port = '', path, hash } = props
  const portWithPrefix = port === '' ? '' : `:${port}`
  const urlLocale = extractLocaleFromPathname(pathname)

  return removeDoubleSlashes(
    `${protocol}//${hostname}${portWithPrefix}/${urlLocale}/${path}${hash}`,
  )
}

export const generateQueryParamsFromIndexUiState = (args: {
  indexUiState: IndexUiState
  indexName: string
  language: string
}) => {
  const { indexUiState, indexName, language } = args

  // key is UrlParam, value is indexUiState facet value
  const refinementListQueryParams = Object.entries(ALGOLIA_FACETS_MAP.refinementList).reduce(
    (acum, [facet, facetProps]) => {
      const key = facetProps.urlParam

      const languageSuffixNeeded =
        'hasLanguageSuffix' in facetProps && facetProps.hasLanguageSuffix
      if (languageSuffixNeeded) facet = `${facet}.${language}`

      const value = indexUiState?.refinementList?.[facet]

      return {
        ...acum,
        [key]: value,
      }
    },
    {} as Exclude<QueryParams, 'search' | 'order'>,
  )

  const queryParameters: QueryParams = {
    // the rest of the filters are from refinementList, so we can map them
    ...refinementListQueryParams,

    // search and order as last items
    query: indexUiState?.query,
    order: indexUiState?.sortBy?.replace(`${indexName}_`, ''),
    // hierarchicalMenu
    [ALGOLIA_FACETS.productCategories.urlParam as string]:
      indexUiState.hierarchicalMenu?.[
        `${ALGOLIA_FACETS.productCategories.facet}.lvl0.${language}`
      ]?.join(' > '),
  }

  return pickBy(queryParameters, isNotEmpty)
}

export const getPagePath = (params) => {
  const { locale, search } = params

  let errorMessage: string | undefined
  if (!locale) {
    errorMessage = `Missing locale info in getCategoryPagePath. Params: ${JSON.stringify(
      params,
    )}`
  }

  if (errorMessage) {
    logger.error(errorMessage)
    throw new Error(errorMessage)
  }

  const normalizedSearch = isNotEmpty(search) ? search : ''

  return `/tools/download-center${normalizedSearch}` as const
}

export const createURL =
  (props: {
    indexName: string
    language: string
  }): BrowserHistoryArgs<IndexUiState>['createURL'] =>
  (args): string => {
    const { indexName, language } = props
    const { location, qsModule, routeState: indexUiState } = args
    const { protocol, hostname, port, hash, pathname } = location

    const locale = extractLocaleFromPathname(pathname)
    const pageSlug = extractPageSlugFromPathname(pathname)
    const queryParameters = generateQueryParamsFromIndexUiState({
      indexUiState,
      indexName,
      language,
    })

    const search = qsModule.stringify(queryParameters, {
      addQueryPrefix: true,
    })

    // https://github.com/algolia/instantsearch/blob/a8943896a19c57fabf54d4b8fea495c57fe6846e/packages/instantsearch.js/src/lib/routers/history.ts#L285
    const pagePath = getPagePath({
      locale,
      pageSlug,
      search,
    })

    const url = generateUrl({
      hash,
      hostname,
      path: pagePath,
      pathname,
      protocol,
      port,
    })

    return url
  }

export const parseURL =
  (props: {
    indexName: string
    language: string
  }): BrowserHistoryArgs<IndexUiState>['parseURL'] =>
  (args) => {
    const { indexName, language } = props
    const { location, qsModule } = args
    const { search } = location

    const queryParams = qsModule.parse(
      search.slice(1), // slice(1) removes the leading '?'
      {
        arrayLimit: 99,
      },
    ) as QueryParams

    const indexUiState = generateIndexUiStateFromQueryParams({
      queryParams,
      indexName,
      language,
    })

    return indexUiState
  }

export const routeToState =
  (props: { indexName: string }): StateMapping<UiState, IndexUiState>['routeToState'] =>
  (indexUiState: IndexUiState = {}) => {
    const { indexName } = props
    const uiState = {
      [indexName]: indexUiState,
    }
    return uiState
  }

export const stateToRoute =
  (props: { indexName: string }): StateMapping<UiState, IndexUiState>['stateToRoute'] =>
  (uiState = {}) => {
    const { indexName } = props
    const routeState = pickBy(uiState[indexName] ?? {}, isNotEmpty)
    return routeState
  }
