import { renderToString } from 'react-dom/server'
import { getServerState } from 'react-instantsearch-core'
import type { GetServerSidePropsContext } from 'next'
import type { Locale } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import type { FooterProps } from '@knauf-group/ct-designs/components/core/Footer'
import type { HeaderMainMenu } from '@knauf-group/ct-designs/components/core/WebHeader'
import type { IAppsConfig } from '@knauf-group/ct-shared-nextjs/client'
import { getAppConfigProps } from '@knauf-group/ct-shared-nextjs/client'
import logger from '@knauf-group/ct-shared-nextjs/logger'
import type { DeviceType } from '@knauf-group/ct-shared-nextjs/utils/deviceType'
import { inferDeviceType } from '@knauf-group/ct-shared-nextjs/utils/deviceType'
import { sharedContentfulProps } from '@knauf-group/ct-shared-nextjs/web'
import Stack from '@mui/material/Stack'
import qs from 'qs'
import { generateServerUrlFromGetServerSidePropsContext } from 'src/utils/generateServerUrlFromContext'
import { splitLocale } from 'src/utils/plain/splitLocale'

import { Layout } from '@/components'
import { usePageView } from '@/hooks'
import type { AlgoliaProviderProps } from '@/pages-components/DownloadCenterPage/components/AlgoliaProvider'
import { AlgoliaProvider } from '@/pages-components/DownloadCenterPage/components/AlgoliaProvider'

import nextI18NextConfig from '../../../next-i18next.config'
import { useTrackScrollPosition } from '../../hooks/analytics/useTrackScrollPosition'
import { FiltersPanel } from './components/MainPanel/FiltersPanel/FiltersPanel'
import { SearchAndHitsPanel } from './components/MainPanel/SearchAndHitsPanel/SearchAndHitsPanel'
import DownloadCenterPageLite from './DownloadCenterPageLite'

type DownloadCenterPageProps = {
  headerEntries: HeaderMainMenu[]
  footerEntries: FooterProps
  appsConfig: IAppsConfig
  algoliaProviderProps: AlgoliaProviderProps
}

const DownloadCenterPageContent = () => {
  usePageView()
  useTrackScrollPosition()

  return (
    <Stack direction="row">
      <FiltersPanel />
      <SearchAndHitsPanel />
    </Stack>
  )
}

const DownloadCenterPage = ({
  footerEntries,
  headerEntries,
  appsConfig,
  algoliaProviderProps,
}: DownloadCenterPageProps) => {
  return (
    <Layout appsConfig={appsConfig} footerEntries={footerEntries} headerEntries={headerEntries}>
      <AlgoliaProvider {...algoliaProviderProps}>
        <DownloadCenterPageContent />
      </AlgoliaProvider>
    </Layout>
  )
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  let headerEntries: HeaderMainMenu[] | null = []
  let footerEntries: FooterProps | null = {} as FooterProps
  const locale = context.locale as Locale
  const { query } = context

  // if language is not in the url queries, then I redirect this request to the same page but with language query
  if (!('language[0]' in query)) {
    const { resolvedUrl } = context
    const { language } = splitLocale(locale)

    const allQueriesString = qs.stringify(
      { ...query, language: [language] },
      { addQueryPrefix: true },
    )
    // get only the base path, without queries
    const basePath = resolvedUrl.split('?')[0]
    const destination = `/${locale}${basePath}${allQueriesString}`

    return {
      redirect: {
        destination,
        permanent: false,
      },
    }
  }

  try {
    const response = await sharedContentfulProps({ locale })
    headerEntries = response.headerEntries
    footerEntries = response.footerEntries
  } catch {
    logger.error('Error while fetching shared contentful props', { locale })
  }

  const serverUrl = generateServerUrlFromGetServerSidePropsContext(context)

  const deviceType: DeviceType | undefined = context.req?.headers['user-agent']
    ? inferDeviceType(context.req.headers['user-agent'])
    : undefined

  const serverState = await getServerState(
    <DownloadCenterPageLite serverUrl={serverUrl} locale={locale} deviceType={deviceType} />,
    { renderToString },
  )

  const algoliaProviderProps = { serverUrl, serverState, locale, deviceType }

  return {
    props: {
      ...(await serverSideTranslations(
        locale,
        ['download-center', 'dam-assets', 'cms'],
        nextI18NextConfig,
      )),
      ...(await getAppConfigProps()),
      headerEntries,
      footerEntries,
      algoliaProviderProps,
    },
  }
}

export default DownloadCenterPage
