import Image from 'next/image'
import { useTranslation } from 'next-i18next'

import type { Theme } from '@mui/material'
import { Box, Typography, useMediaQuery } from '@mui/material'

import { ASSET_PREFIX } from '@/constants'
import { removeDoubleSlashes } from '@/utils'

const getStaticAssetPath = (path: string) => removeDoubleSlashes(`/${ASSET_PREFIX}/${path}`)

export const NoRowsOverlay = () => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.hitsDataGrid',
  })
  const isScreenMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))
  const errorIllustrationSvgPath = getStaticAssetPath(`/svg/error-illustration.svg`)

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="10px"
    >
      <Typography variant="h2">{t('noResults')}</Typography>
      <Typography color="text.secondary" variant="body2" sx={{ padding: '10px' }}>
        {t('noResultsOther')}
      </Typography>
      <Image
        src={errorIllustrationSvgPath}
        alt="no results"
        width={isScreenMdUp ? 390 : 164}
        height={isScreenMdUp ? 390 : 164}
        data-cy="no-results-image"
      />
    </Box>
  )
}
